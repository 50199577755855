@import url(https://fonts.googleapis.com/css?family=Montserrat:600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat:600&display=swap);
.dashboard {
    width: 100%;
    height: 82vh; 
}
.image-cover-container {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.dashboard-title {
    font-size: 2.2rem;
    text-align: center;
}

.dashboard-searchbox {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-searchbox-input {
    height: 30px;
    width: 300px;
    border-radius: 8px;
    background-color: #F2F2F2;
    border: 1px solid #b3b3b3;
    padding: 0 4px;
    margin-right: 1rem;
}

.dashboard-searchbox-button {
    height: 30px;
    width: 100px;
    border: none;
    border-radius: 8px;
    color: #fff;
    background-color: #1DB954;
    font-weight: 700;
    cursor: pointer;
}

.my-top-five {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.followed {
    width: 30%;
    padding: 0px 4px;
    margin-right: 3rem;
}

.saved-tracks {
    width: 30%;
    padding: 0px 4px;
    margin-right: 3rem;
}

.saved-albums {
    width: 30%;
    padding: 0px 4px;
}

/* --------------------------------------------- dash.details --------------------------------------------- */

.background {
	width: 100%;
	height: 82vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.dash-options {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.artists:hover{
	cursor: pointer;
	color: blueviolet;
}

.albums:hover{
	cursor: pointer;
	color: blueviolet;
}

.tracks:hover{
	cursor: pointer;
	color: blueviolet;
}

.home-container {
    width: 100%;
    height: 82vh;
    display: flex;
    flex-direction: row;
}

.home-left-child {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    min-width: 400px;
    padding-left: 4rem;
    padding-right: 4rem;
    height: 100vh;
    align-items: flex-start;
}

.home-left-child h3 {
    font-weight: 800;
    line-height: 50px;
    font-size: 3.3rem;
    margin: 0;
}

.home-left-child h6 { 
    margin-top: 6%;
    margin-bottom: 6%;
    font-size: 1.5rem;
} 

.home-left-child button {
    border: none;
    background-color: #1DB954;
    width: 164px;
    height: 52px;
    border-radius: 10px;
    color: white;
    font-size: 1.3rem;
    font-weight: 700;
    cursor: pointer;
}

.home-right-child {
    width: 60%;
    background-position: center;
    background-repeat: no-repeat;
    min-width: 560px;
    background-size: contain;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
}

.image-container {
  height: 320px;
}

.body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  margin-top: 3rem;
}

.left-container {
  width: 30%;
}

.right-container {
  width: 30%;
}

a {
  text-decoration: none;
  color: rgb(224, 99, 126);
}

a:hover {
  color: red;
}

/*




-------------------------





*/

#shadowBox {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/opacity/see-through */
  border: 3px solid;
}

.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
}
.rainbow_text_animated {
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-animation: rainbow_animation 6s ease-in-out infinite;
          animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@-webkit-keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

/*




-------------------------





*/

.button-dashboard{
  position: relative;
  display: inline-flex;
  width: 120px;
  height: 55px;
  margin: 0 15px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
.button-dashboard a{
  font-size: 16px;
  letter-spacing: 1px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(-25px);
          transform: translateZ(-25px);
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
  font-family: 'Montserrat', sans-serif;
  
}
.button-dashboard a:before,
.button-dashboard a:after{
  position: absolute;
  content: "Dashboard";
  height: 55px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid black;
  box-sizing: border-box;
  border-radius: 5px;
}
.button-dashboard a:before{
  color: #fff;
  background: #000;
  -webkit-transform: rotateY(0deg) translateZ(25px);
          transform: rotateY(0deg) translateZ(25px);
}
.button-dashboard a:after{
  color: #000;
  -webkit-transform: rotateX(90deg) translateZ(25px);
          transform: rotateX(90deg) translateZ(25px);
}
.button-dashboard a:hover{
  -webkit-transform: translateZ(-25px) rotateX(-90deg);
          transform: translateZ(-25px) rotateX(-90deg);
}

.main-container {
  display: flex;
  flex-direction: column;
}

.head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
}

.image-container {
  height: 320px;
}

.body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  margin-top: 3rem;
}

.left-container {
  width: 30%;
}

.mid-container {
  width: 30%;
}

.right-container {
  width: 30%;
}

a {
  text-decoration: none;
  color: rgb(224, 99, 126);
}

a:hover {
  color: red;
}

/*




-------------------------





*/

#shadowBox {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/opacity/see-through */
  border: 3px solid;
}

.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
}
.rainbow_text_animated {
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-animation: rainbow_animation 6s ease-in-out infinite;
          animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@-webkit-keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

/*




-------------------------





*/

.button-dashboard{
  position: relative;
  display: inline-flex;
  width: 120px;
  height: 55px;
  margin: 0 15px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
.button-dashboard a{
  font-size: 16px;
  letter-spacing: 1px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(-25px);
          transform: translateZ(-25px);
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
  font-family: 'Montserrat', sans-serif;
  
}
.button-dashboard a:before,
.button-dashboard a:after{
  position: absolute;
  content: "Dashboard";
  height: 55px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid black;
  box-sizing: border-box;
  border-radius: 5px;
}
.button-dashboard a:before{
  color: #fff;
  background: #000;
  -webkit-transform: rotateY(0deg) translateZ(25px);
          transform: rotateY(0deg) translateZ(25px);
}
.button-dashboard a:after{
  color: #000;
  -webkit-transform: rotateX(90deg) translateZ(25px);
          transform: rotateX(90deg) translateZ(25px);
}
.button-dashboard a:hover{
  -webkit-transform: translateZ(-25px) rotateX(-90deg);
          transform: translateZ(-25px) rotateX(-90deg);
}

/*




-------------------------





*/

.follow-button {
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  line-height: 1;
  border-radius: 500px;
  transition-property: background-color,border-color,color,box-shadow,-webkit-filter;
  transition-property: background-color,border-color,color,box-shadow,filter;
  transition-property: background-color,border-color,color,box-shadow,filter,-webkit-filter;
  transition-duration: .3s;
  border: 1px solid transparent;
  letter-spacing: 2px;
  text-transform: uppercase;
  white-space: normal;
  font-weight: 700;
  text-align: center;
  color: #fff;
  background-color: #15883e;
  height: 24px;
}

.follow-button:hover{
  background-color: #1db954;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.head-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 0.5rem;
}

.image-container {
  height: 320px;
}

.body-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;

  margin: 3rem 0rem;
}

.left-container {
  width: 30%;
}

a {
  text-decoration: none;
  color: rgb(224, 99, 126);
}

a:hover {
  color: red;
}

/*




-------------------------





*/

#shadowBox {
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/opacity/see-through */
  border: 3px solid;
}

.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 32px;
  font-family: monospace;
  letter-spacing: 5px;
}
.rainbow_text_animated {
  background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-animation: rainbow_animation 6s ease-in-out infinite;
          animation: rainbow_animation 6s ease-in-out infinite;
  background-size: 400% 100%;
}

@-webkit-keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

@keyframes rainbow_animation {
  0%,100% {
      background-position: 0 0;
  }

  50% {
      background-position: 100% 0;
  }
}

/*




-------------------------





*/

.button-dashboard{
  position: relative;
  display: inline-flex;
  width: 120px;
  height: 55px;
  margin: 0 15px;
  -webkit-perspective: 1000px;
          perspective: 1000px;
}
.button-dashboard a{
  font-size: 16px;
  letter-spacing: 1px;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  -webkit-transform: translateZ(-25px);
          transform: translateZ(-25px);
  transition: -webkit-transform .25s;
  transition: transform .25s;
  transition: transform .25s, -webkit-transform .25s;
  font-family: 'Montserrat', sans-serif;
  
}
.button-dashboard a:before,
.button-dashboard a:after{
  position: absolute;
  content: "Dashboard";
  height: 55px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 5px solid black;
  box-sizing: border-box;
  border-radius: 5px;
}
.button-dashboard a:before{
  color: #fff;
  background: #000;
  -webkit-transform: rotateY(0deg) translateZ(25px);
          transform: rotateY(0deg) translateZ(25px);
}
.button-dashboard a:after{
  color: #000;
  -webkit-transform: rotateX(90deg) translateZ(25px);
          transform: rotateX(90deg) translateZ(25px);
}
.button-dashboard a:hover{
  -webkit-transform: translateZ(-25px) rotateX(-90deg);
          transform: translateZ(-25px) rotateX(-90deg);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  background-color: rgb(243, 243, 243);
}

